import React, { Component } from "react";
import "./timer.css";

const MAX_PLAYTIME = 10;

class App extends Component {
  intervalId;

  constructor(props) {
    super(props);

    this.state = {
      countdownTimer: MAX_PLAYTIME,
      tick : this.tick.bind(this)
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({ countdownTimer: this.state.countdownTimer - 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { player, topScore, onGameOver } = this.props;
    const { countdownTimer } = this.state;

    if (countdownTimer === 0) {
        this.state.countdownTimer=10;//
    }

    return (

      
      <div
        className="qr-corner-wrapper"
        style={{
          float: "right",
          paddingRight: "10px",
          paddingTop: "2vh",
          fontSize: "9pt",
        }}
      >
        <b>
          <div>Timer: {countdownTimer}</div>
        </b>
      </div>
    );
  }
}

export default App;
