import React from "react";
import welcome from "./welcome/welcome";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import InfoPage from "./InfoPage/InfoPage";
import FormsHappiness from "./forms/Form";
import main from "./CameraView/cameraView";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={welcome}></Route>
        <Route path="/welcome" exact={true} component={welcome}></Route>
        <Route path="/info" exact={true} component={InfoPage}></Route>
        <Route
          path="/happiness-form"
          exact={true}
          component={FormsHappiness}
        ></Route>
        <Route path="/main-part" exact={true} component={main}></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
