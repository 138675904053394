import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import gif from "./HappinessIcon.gif";
import Logo from "./Synthesis-01.png";
function WelcomePage() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/info");
    }, 5000);
  }, []);

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: "170px",
        backgroundColor: "rgb(251,251,251)",
        height: "100vh"
      }}
    >
      <img src={gif} alt="loading..." width={100} height={100} />
      <h6
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "40px"
        }}
      >
        {" "}
        International Happiness Week{" "}
      </h6>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "170px",
        }}
      >
        <img src={Logo} alt="Logo" width={100} height={20} style={{textAlign: "center"}} />
      </div>
    </div>
  );
}

export default WelcomePage;
