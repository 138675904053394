import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import logo from "./infoicon.png";

function InfoPage(props) {
  return (
    <div>
      <h4
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        {" "}
        Does performing acts of <br /> kindness make us happy?
      </h4>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "40px",
        }}
      >
        <img src={logo} alt="logo" width={150} height={150} />
      </div>{" "}
      <h6
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          textAlign: "center",
          paddingTop: "20px",
        }}
      >
        {" "}
        We are officially extending International Happiness Day for a week –
        because why limit happiness?{" "}
      </h6>
      <p
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "50px",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontSize: "10pt",
        }}
      >
        1. All you need to do is commit to an act of kindness and then smile at
        the camera.
        <br />
        2. Our AI will tell you just how happy you are.
        <br /> -Don't believe us? Give it a try.
        <br /> -There is no downside.
      </p>{" "}
      <div>
        <Button
          style={{
            backgroundColor: "#00263E",
            position: "relative",
            borderRadius: "8px",
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
        >
          <a
            href="happiness-form"
            style={{ color: "#FFF", textDecoration: "none" }}
          >
            Next
          </a>
        </Button>{" "}
      </div>
    </div>
  );
}

export default InfoPage;
