import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container, Form } from "react-bootstrap";
import logo from "./Synthesis-01.png";

//if issue check where pnp logo stored
function FormsHappiness(props) {
  const [fullName, setFullName] = useState("");
  const [emailAddr, setemailAddr] = useState("");
  const [Kindness, setKindness] = useState("");
  const [Charity, setCharity] = useState("");
  const [City, setCity] = useState("");
  const [agree, setAgree] = useState(false);
  const [NewsLetter, setNewsLetter] = useState(false);

  const fullNameTextHandler = (e) => {
    setFullName(e.target.value);
  };

  const emailAddrTextHandler = (e) => {
    setemailAddr(e.target.value);
  };

  const KindnessTextHandler = (e) => {
    setKindness(e.target.value);
  };

  const CharityTextHandler = (e) => {
    setCharity(e.target.value);
  };

  const CityTextHandler = (e) => {
    setCity(e.target.value);
  };

  const submitHandler = () => {
    let data = {
      UserName: fullName,
      EmailAddress: emailAddr,
      Kindness: Kindness,
      Charity: Charity,
      City: City,
      NewsLetter: NewsLetter,
    };
    window.localStorage.setItem("data", JSON.stringify(data));
    console.log(window.localStorage.getItem("data"))
    console.log("Posted");
  };

  const canBeSubmitted = () => {
    const isValid = agree; // checkbox for terms

    if (isValid) {
      document.getElementById("submitButton").removeAttribute("disabled");
    } else {
      document.getElementById("submitButton").setAttribute("disabled", true);
    }
  };
  useEffect(() => canBeSubmitted());

  return (
    <Container>
      <div style={{ display: "block", height: 580, padding: 30 }}>
        <div style={{ textAlign: "center" }}>
          <h4>International Happiness Week </h4>
          <h6>#SpreadHappiness</h6>
        </div>
        &nbsp;
        <Form>
          <Form.Group>
            <Form.Control
              id="Name"
              type="text"
              placeholder="Full name"
              onChange={fullNameTextHandler}
              value={fullName}
            />
          </Form.Group>
          <div
            style={{
              paddingTop: "5px",
            }}
          >
            <Form.Group>
              <Form.Control
                id="Email"
                type="email"
                placeholder="Email address"
                onChange={emailAddrTextHandler}
                value={emailAddr}
              />
            </Form.Group>
          </div>
          <div
            style={{
              paddingTop: "5px",
            }}
          >
            <Form.Group>
              <Form.Control
                id="Kindness"
                type="text"
                placeholder="Act of Kindness"
                onChange={KindnessTextHandler}
                value={Kindness}
              />
            </Form.Group>
          </div>
          <div
            style={{
              paddingTop: "5px",
            }}
          >
            <Form.Group>
              <Form.Control
                id="Charity"
                type="text"
                placeholder="Charity (if applicable)"
                onChange={CharityTextHandler}
                value={Charity}
              />
            </Form.Group>
          </div>
          <div
            style={{
              paddingTop: "5px",
            }}
          >
            <Form.Group>
              <Form.Control
                id="City"
                type="text"
                placeholder="City"
                onChange={CityTextHandler}
                value={City}
              />
            </Form.Group>
          </div>
          <br />
          <div style={{ fontSize: "0.7rem" }}>
            <Form.Group controlId="formBasicCheckbox">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Check
                  type="checkbox"
                  id="agree"
                  onClick={(e) => setAgree(e.target.checked)}
                />{" "}
                &nbsp;I Accept the&nbsp;
                <a
                  href="https://www.synthesis.co.za/international-happiness-day-tcs/"
                  download
                >
                  Terms of service
                </a>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox"
              Typography
              variant="body1"
            >
              <Form.Check
                type="checkbox"
                label="Sign up for the Synthesis newsletter"
              />
            </Form.Group>
          </div>
          <div
            className="submitB"
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button //B if need button to be diasbaled
              onClick={submitHandler}
              id="submitButton"
              style={{
                backgroundColor: "#00263E",
                position: "relative",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <a href="main-part" style={{ color: '#FFF', textDecoration: 'none' }} >Submit</a>
            </Button>
            {""}
            <br></br>
            <br></br>
            <img src={logo} alt="Logo" width={100} height={20} />
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default FormsHappiness;
