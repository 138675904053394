import React from "react";
import Webcam from "react-webcam";
import "bootstrap/dist/css/bootstrap.min.css";

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: "user",
};

export default class WebcamCapture extends React.Component {

  constructor(props) {
    super(props);
    setInterval(() => this.capture(), 1000);
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    if (this.webcam) {
      this.imageSrc = this.webcam.getScreenshot();
      this.props.handleCameraOutput(this.imageSrc);
    }
  };

  render() {
    return (
      <div style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
      }}>
      <Webcam
        audio={false}
        height={"110%"}
        ref={this.setRef}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
      /></div>
    );
  };
}
