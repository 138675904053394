import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Placeholder,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import WebcamCapture from "../webcam/webcam";
import HappyMeter from "../happymeter/happymeter";
import App from "../CameraView/timer";
import { saveAs } from "file-saver";
import Logo from "./Synthesis-01.png";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AWS, {
  Rekognition,
  CognitoIdentityCredentials,
  Discovery,
} from "aws-sdk";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Buffer } from "buffer";
import { render } from "@testing-library/react";
import mergeImages from 'merge-images';

Buffer.from("anything", "base64");

AWS.config.region = "eu-west-1"; // Region
AWS.config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: "eu-west-1:60c1f516-872d-444d-8a07-98f083cdb5ce",
});
const MAX_PLAYTIME = 10;
const rekognition = new Rekognition();

// const saveFile = (url) => {
//   saveAs(
//     window.name,
//     "post.jpg"
//   );
// };
//Window.name is a global variable I have set in submit handler api fetch request

// const submitHandler = () => {
//   console.log(JSON.parse(window.localStorage.getItem("data")))
//   const data = JSON.parse(window.localStorage.getItem("data"));
//   var xhr = new XMLHttpRequest();
//   xhr.open("POST", "https://ciji1tdyy4.execute-api.eu-west-2.amazonaws.com/happiness-beta");
//   console.log("Created the Post Request");
//   xhr.setRequestHeader("Accept", "application/json");
//   xhr.setRequestHeader("Content-Type", "application/json");
//   xhr.send(JSON.stringify(data))
// }
// const data = JSON.parse(window.localStorage.getItem("data"));
// // the desired aspect ratio of our output image (width / height)
// const outputImageAspectRatio = 1;
// // this image will hold our source image data
// const inputImage = new Image();
// // we want to wait for our image to load
// inputImage.onload = () => {
//     // let's store the width and height of our image
//     const inputWidth = inputImage.naturalWidth;
//     const inputHeight = inputImage.naturalHeight;
//     // get the aspect ratio of the input image
//     const inputImageAspectRatio = inputWidth / inputHeight;
//     // if it's bigger than our target aspect ratio
//     let outputWidth = 1090;
//     let outputHeight = 1090;
//     if (inputImageAspectRatio > outputImageAspectRatio) {
//         outputWidth = inputHeight * outputImageAspectRatio;
//     } else if (inputImageAspectRatio < outputImageAspectRatio) {
//         outputHeight = inputWidth / outputImageAspectRatio;
//     }
//     // calculate the position to draw the image at
//     const outputX = (outputWidth - inputWidth) * 0.5;
//     const outputY = (outputHeight - inputHeight) * 0.5;

//     // create a canvas that will present the output image
//     const outputImage = document.createElement('canvas');

//     // set it to the same size as the image
//     outputImage.width = outputWidth;
//     outputImage.height = outputHeight;

//     // draw our image at position 0, 0 on the canvas
//     const ctx = outputImage.getContext('2d');
//     ctx.drawImage(inputImage, outputX, outputY);
//     // show both the image and the canvas
//     document.body.appendChild(inputImage);
//     document.body.appendChild(outputImage);
// };
// // start loading our image
// inputImage.src=(this.state.Image.image.Bytes);

class Camera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      frameImageUrl:"https://happiness.synthesis.events/images/svhcjlMH0v.jpg",
      previewImageUrl: './HappinessIcon.gif',
      screenShot: '',
      webcamEnabled: false,
      previewScreen: false,
      happyScore: 0,
      // avgScore: [0,0,0,0,0],
      // rekognitionCounter: 0,
      countdown: MAX_PLAYTIME,
      seconds: 10,
      secondsTemp: 10,
      tick: this.tick.bind(this),
      maxScore: 0,
      encodedImage: {
        Image: {
          Bytes: '',
        },
      }
    };
  }
  submitHandler () {

    // console.log(JSON.parse(window.localStorage.getItem("data")))
    //Create New Post
    let imageData = {
        "image": this.state.screenShot
    }
    const requestObject = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(imageData)
    }

    fetch("https://q2lr4hl7oh.execute-api.eu-west-2.amazonaws.com/Test/image-upload",requestObject)
    .then(response => response.json())
    .then(data => {
         this.setState({
           frameImageUrl : data.body
         })
         window.name = data.body;
         window.urlname = data.body.substring(42,52);

        
         console.log(window.name)
         var tempObject = JSON.parse(window.localStorage.getItem("data"))
         tempObject.ImageUrl = data.body
         tempObject.MaxScore = this.state.maxScore
         console.log(tempObject)
        //  console.log(data.body)
         const requestObject = {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(tempObject)
        }
        fetch("https://ciji1tdyy4.execute-api.eu-west-2.amazonaws.com/happiness-beta",requestObject)
    });
  }
  
  tick() {
    if (this.state.webcamEnabled == true) {
      this.setState((prevState) => ({
        seconds: prevState.seconds - 1,
      }));
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  enableWebcam = () => {
    this.setState({ webcamEnabled: !this.state.webcamEnabled });
  };

  handleCameraScreenshot = (screenshot) => {
    if (screenshot) {
      this.recogniseHappiness(screenshot);
    }
  };

  recogniseHappiness = (image) => {
    var params = {
      Image: {
        /* required */ Bytes: new Buffer.from(image.split(",")[1], "base64"),
      },
      Attributes: ["ALL"],
    };

    rekognition
      .detectFaces(params)
      .promise()
      .then((data) => {
        if (data.FaceDetails && data.FaceDetails.length) {
          if (data.FaceDetails.length > 1) {
            data.FaceDetails.sort(
              (face1, face2) =>
                face2.BoundingBox.Height * face2.BoundingBox.Width -
                face1.BoundingBox.Height * face1.BoundingBox.Width
            );
          }

          const rekHappyScore = data.FaceDetails[0].Emotions.filter(
            (item) => item.Type === "HAPPY"
          )[0]["Confidence"];
          this.setState((prevState) => ({
            happyScore: ((prevState.happyScore + rekHappyScore) / 2),
          }));
          if (this.state.happyScore >= this.state.maxScore) {
            this.setState({
              maxScore: this.state.happyScore,
              encodedImage: params,
              screenShot: image
            })
          }
        }
      })
      .catch((err) => console.log(err, err.stack));
  };

  // composeImage = () => {
  //   Jimp.read(this.state.Image.image.Bytes)
  //     .then(faceImage => {
  //       faceImage.resize(1090, Jimp.AUTO)
  //       Jimp.read('./Happiness_App_Frame-02-01.png')
  //         .then(frameImage => {
  //           faceImage.composite(frameImage, 0, 0)
  //           this.setState({ image: { Image: { Bytes: faceImage }}})
  //         })
  //         .catch(err => {
  //           console.log(err)
  //           console.log("Failed to load frame image")
  //         })
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       console.log("Failed to load face image")
  //     });
  // }

  // arrayBufferToBase64 = buffer => {
  //   let binary = '';
  //   let bytes = new Uint8Array(buffer);
  //   let len = bytes.byteLength;
  //   for (let i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return window.btoa(binary);
  // };

  render() {

    if (this.state.seconds === 1) {
      this.state.previewScreen = true;
    }

    if (this.state.seconds === 0) {
      this.state.seconds = 10;
      this.state.happyScore = 0;
      this.enableWebcam();
      this.submitHandler(); 
    }
    return (
      <Container fluid className="App">
        <Row className="logoContainer">
          <div
            style={{
              background: "#00354C",
              height: "8vh",
              paddingTop: "1vh",
            }}
          >
            <Col style={{ background: "#00354C" }}>
              <Image
                src={require("./HappinessIconSynthesisBlue.png")}
                fluid
                style={{
                  height: "5vh",
                  paddingTop: "1vh",
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              />{" "}
              <div
                style={{
                  float: "right",
                  paddingRight: "10px",
                  paddingTop: "2vh",
                  fontSize: "9pt",
                  color: "#FFF"
                }}
              >
                Time: {this.state.seconds}{" "}
              </div>
            </Col>
          </div>
        </Row>{" "}
        {this.state.webcamEnabled ? (
          <div style={{
            paddingTop: "10vh",
            justifyContent: "center",
            textAlign: "center",
            height: "50%",
          }}>
            <Container fluid >
              <Row>
                <Col>
                  <WebcamCapture
                    handleCameraOutput={this.handleCameraScreenshot}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : this.state.previewScreen ? (<div>
          <Container>
            <Row>
              <Col>
                <div
                  style={{
                    paddingTop: "10vh",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Your Happiest Image!
                  <Image src={this.state.frameImageUrl} height={"100%"} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>) : (
          <div>
            <Container>
              <Row>
                <Col>
                  <div
                    style={{
                      paddingTop: "10vh",
                      justifyContent: "center",
                      textAlign: "center",
                      height: "50%",
                    }}
                  >
                    <Image src={require("./Avatar-white.png")} height={"80%"} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )
        }
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            background: "#00354C",
            width: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
            marginRight: "0px",
            marginLeft: "0px",
            width: "100%",
          }}
        >
          <Row
            style={{
              width: "100%",
              height: "8vh",
              background: "#00354C",
            }}
          >
            <Col xs={3} style={{ background: "#00354C" }}>
              <Container>
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "1vh",
                    color: "#FFF",
                    textDecoration: "none",
                    float: "right",
                    paddingRight: "10px",
                    paddingTop: "2vh",
                    fontSize: "9pt",
                  }}
                >
                  <Image
                    onClick={() => {
                      this.enableWebcam();
                      this.state.seconds = 10
                    }}
                    src={require("./CameraOn.png")}
                    rounded
                    className="buttonImage"
                    height={"50vh"}
                  />
                </div>
              </Container>
            </Col>
            <Col xs={7} style={{ background: "#00354C" }}>
              <HappyMeter score={this.state.happyScore} />
            </Col>
            <Container>
              <Col style={{ background: "#00354C" }}>
                <Display 
                  imgUrl={this.state.frameImageUrl}
                />
              </Col>
            </Container>
          </Row>
        </div>
      </Container >
    );
  }
}

function MyVerticallyCenteredModal(props) {
  const saveFile = (url) => {
    saveAs(
      window.name,
      "post.jpg"
    );
  };

  const saveUrl = (url) => {
    saveAs(
      window.name,
      "https://happiness.synthesis.events/images/randomname.jpg"
    );
  };

  return (
    <div>
      <Modal
        {...props}
        size="sl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          width: "95%",
        }}
      >
        <Modal.Header closeButton>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              Share this
              <h6>#SpreadHappiness</h6>
            </Modal.Title>{" "}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              textAlign: "center",
            }}
          >
           <FacebookShareButton
               title="International Happiness Week"
               quote={
                "Help me spread the happiness. \n\n  I just made someone’s day by doing an act of kindness and according to AI it has made me super happy.​ \n\nLearn your happiness score now​.\n\n#SpreadHappiness #InternationHappinessWeek"
               }
               url={"https://happiness.synthesis.events/images/" + window.urlname +".jpg"}
               hashtag={"#SpreadHappiness?"}
               className="Demo__FB"
             >
               {" "}
               <FacebookIcon size={32} round />
             </FacebookShareButton>
             &nbsp;
            <TwitterShareButton
              title={"International Happiness Week"}
              url={"https://summit.synthesis.events/"}
              quote={
                "Help me spread the happiness. \n\n  I just made someone’s day by doing an act of kindness and according to AI it has made me super happy.​ \n\nLearn your happiness score now.\n\n#SpreadHappiness #InternationHappinessWeek"
              }
              hashtag={"#SpreadHappiness?"}
              className="Demo__Twitter"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            &nbsp;
            <LinkedinShareButton
              title={"International Happiness Day"}
              url={"https://summit.synthesis.events/"}
              quote={
                "Help me spread the happiness. \n\n  I just made someone’s day by doing an act of kindness and according to AI it has made me super happy.​ \n\nLearn your happiness score now​.\n\n#SpreadHappiness #InternationHappinessWeek"
              }
              hashtag={"#SpreadHappiness?"}
              className="Demo__LinkedIn"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            &nbsp;
            <WhatsappShareButton
              title={"International Happiness Week"}
              url={"https://summit.synthesis.events/"}
              quote={
                "Help me spread the happiness. \n\n  I just made someone’s day by doing an act of kindness and according to AI it has made me super happy.​ \n\nLearn your happiness score now​.\n\n#SpreadHappiness #InternationHappinessWeek"
              }
              hashtag={"#SpreadHappiness?"}
              className="Demo__LinkedIn"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <br></br>
          <div style={{ textAlign: "center" }}>
            <br></br>
            Thanks for spreading the happiness!
            <br></br>
            Let's share and spread it further
            <br></br>
            <br></br>
          </div>
          <div>
            <h6
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              Download your image below{" "}
            </h6>
            <a
              href={props.imgUrl}
              download
            ></a>
            <div style={{ textAlign: "center" }}>
              <Container className="download-button">
                <button variant="outline-primary" onClick={ () => saveFile(props.imgUrl)}>
                  <Image src={require("./Download.png")} fluid rounded />
                </button>
              </Container>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ textAlign: "center", paddingRight: "11vh" }}>
            <img src={Logo} alt="Logo" width={100} height={20} />
          </div>
          <Button
            onClick={props.onHide}
            style={{
              backgroundColor: "#00263E",
              position: "relative",
              borderRadius: "8px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function Display(imgUrl) {
  const [modalShow, setModalShow] = React.useState(false);
  // const [frameImageUrl, setFrameImageUrl] = React.useState(false);
  
  let modal;
  if(modalShow) {
    modal =  <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} imgUrl = {imgUrl} />
  }else{
     modal = <></>
  }

  return (
    <>
      <div
        style={{
          background: "#00354C",
        }}
      >
        <Button
          backgroundColor={"#00263E"}
          onClick={() => { setModalShow(true);  }} //submitHandler();
          disable={true}
          style={{
            backgroundColor: "#00354c",
            borderColor: "#00354c",
            borderRadius: "8px",
            position: "absolute",
            paddingBottom: "1.8vh",
            right: 10,
            bottom: 10,
            backgorund: "none",
            borderColor: "#00354c",
            boxShadow: 'none'
          }}
        >
          Share
        </Button>
        {""}
      </div>
      {modal}
    </>
  );
}

export default Camera;
