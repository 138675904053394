import React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import "./happymeter.css";

export default class HappyMeter extends React.Component {
  progressBar;

  constructor(props) {
    super(props);

    this.state = {
      score: Math.round(this.props.score),
    };
  }

  progressBarStyle() {
    if (Math.round(this.props.score) <= 33) {
      return <ProgressBar variant="danger" now={Math.floor(this.props.score)} />;
    } else if (Math.round(this.props.score) <= 66) {
      return (
        <ProgressBar variant="warning" now={Math.round(this.props.score)} />
      );
    } else if (Math.round(this.props.score) <= 100) {
      return <ProgressBar variant="success" now={Math.ceil(this.props.score)} />;
    }
  }

  render() {
    return (
      <div
        style={{
          paddingTop: "2.7vh",
        }}
      >
        <Container className="happy-meter-container">
          <Row>
            <Col>
              <Container className="progressBar">
                {this.progressBarStyle()}
              </Container>
            </Col>
            <div
              style={{
                textAlign: "center",
                paddingTop: "0.3vh",
                paddingLeft: "10vh",
              }}
            >
              <Col xs={2} className="textContainer">
                {Math.round(this.props.score)}%
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

HappyMeter.defaultProps = {
  score: 0,
};
